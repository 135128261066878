<template>
  <div class="map-modal" :style="topStyle">
    <div class="map-modal-content">
      <p
        ref="MAP_MODEL_TITLE"
        style="text-align: center; margin:auto; margin-bottom: 15px; font-weight: 600; font-size:1rem;"
      >
        اضغط على الخريطة لتضع علامة توضح لزبائنك مكان تواجدك
      </p>
      <div ref="MAP_CONTAINER" id="map-c"></div>
      <div class="m-control">
        <button class="mujeeb-button green" @click="onSubmitClicked">اعتماد الموقع</button>
        <button class="mujeeb-button red" @click="onCnacleClicked">الغاء</button>
      </div>
    </div>
  </div>
</template>

<script>
import gmapsInit from "./../../utils/googleMap";
export default {
  name: "App",
  props: {
    top: {
      type: Number
    },
    latlng: {
      type: Object,
      default: function() {
        return null;
      }
    }
  },
  data() {
    return {
      marker: null,
      map: null,
      geocoder: null,
      selectedLatlng: null
    };
  },
  methods: {
    onCnacleClicked() {
      if (this.map && this.marker) {
        // this.marker = null
        this.marker.setMap(null);
      }
      this.$emit("close");
    },
    onSubmitClicked() {
      if (this.selectedLatlng) {
        this.$emit("update:latlng", this.selectedLatlng);
        this.$emit("close");
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب عليك اختيار مكان ما عن طريق الضغط على الخريطة",
          position: "is-top",
          type: "is-warning"
        });
      }
    }
  },
  computed: {
    topStyle() {
      return `top:${this.top}px;`;
    }
  },
  async mounted() {
    try {
      // *Begining of initialization section
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      const map = new google.maps.Map(this.$refs["MAP_CONTAINER"]);
      this.map = map;
      this.geocoder = geocoder;
      let marker = null;
      // *End of the initialization section

      // *Begining of the rendiering section
      if (this.latlng && Object.keys(this.latlng).length !== 0) {
        marker = new google.maps.Marker({ position: this.latlng, map: map });
        this.marker = marker;
        map.setZoom(15);
        map.setCenter(marker.getPosition());
      } else {
        geocoder.geocode({ address: "Syria" }, (results, status) => {
          if (status !== "OK" || !results[0]) {
            throw new Error(status);
          }
          map.setCenter(results[0].geometry.location);
          map.fitBounds(results[0].geometry.viewport);
        });
      }
      // *End of the rendiering section

      // *Begining of the event section
      google.maps.event.addListener(
        map,
        "click",
        function(event) {
          // remove all markers and set this one only
          if (marker && marker.getMap()) marker.setMap(null);
          marker = new google.maps.Marker({
            position: event.latLng,
            map: map,
            animation: google.maps.Animation.DROP
          });
          this.marker = marker;
          const markerPosition = marker.getPosition();
          const latlng = {
            lat: parseFloat(markerPosition.lat()),
            lng: parseFloat(markerPosition.lng())
          };
          this.selectedLatlng = latlng;
          geocoder.geocode({ location: latlng }, function(results, status) {
            if (status === "OK") {
              if (!results[0]) {
                window.alert("No results found");
              }
            } else {
              window.alert("Geocoder failed due to: " + status);
            }
          });
          // google.maps.event.addListener(marker, 'click', function () {
          // }.bind(this))
      }.bind(this)) // eslint-disable-line
      // *End of the event section
    } catch (error) {
      console.error(error);
    }
  }
};
</script>

<style scoped lang="scss">
// @import "./../../../../static/sass/_variables.scss";
.map-modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(63, 63, 63, 0.295);
  z-index: 10;
  // height: 100%;
  height: calc(100vh - 50px);
  .map-modal-content {
    position: relative;
    margin: auto;
    height: 100%;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    overflow-y: hidden;
    // ____
    display: flex;
    flex-direction: column;
    .m-control {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 5%;
      margin-top: 15px;
      // ____
      flex: 0 30px;
      button {
        margin-buttom: 0px;
        &:first-child {
          margin-right: 20px;
        }
      }
    }
    #map-c {
      border-radius: $border-radius-xs;
      // height: calc(100% - 297px);
      height: 60%;
      width: 100%;
      // ___
      flex: 4 1 300px;
    }
  }
}
</style>
