<template>
  <div class="m-modal-componenet">
    <div class="m-modal-componenet__content" :style="styling.modalContent">
      <div class="content__header" style="text-align: center;" :style="styling.modalHeader">
        <slot name="header-slot"></slot>
      </div>

      <div class="content__body" :style="styling.modalBody">
        <slot name="body-slot"></slot>
      </div>

      <div class="content__footer" :style="styling.modalfooter">
        <slot name="footer-slot"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    styling: {
      type: Object,
      default: function() {
        return {
          modalContent: {},
          modalBody: {
            width: "100%"
          }
        };
      }
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
// @import "./../../../static/sass/_variables.scss";

.m-modal-componenet {
  z-index: 700;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .m-modal-componenet__content {
    display: inline-block;
    background: white;
    padding: 15px;
    max-height: 80%;
    width: 95%;
    margin: 2.5%;
    overflow-y: auto;
    overflow-x: hidden;

    .content__header {
      margin-bottom: 35px;
      font-size: $header-size;
      font-weight: bold;
    }

    .content__footer {
      text-align: center;
      margin-top: 35px;
    }

    .content__body {
      font-size: $header-size - 2;
      text-align: center;
      font-weight: 600;
      p {
        line-height: 30px;
      }
      .guide-img {
        width: 300px;
        height: auto;
        margin: 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .m-modal-componenet__content {
    width: 70vw;
  }
}
</style>
