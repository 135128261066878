<template>
  <div ref="contactUs_Component" class="m-main-section" @scroll="onScrolling">
    <p class="section-title">
      هنا يتم إدخال معلومات التواصل مع صفحة {{ $store.getters["page/name"] }}
    </p>
    <genericModal v-if="showInitializationModal">
      <template>
        <template slot="header-slot" v-if="step === 1">
          <p class="rtl">ماهي هذه الخدمة؟</p>
          <!-- <p class="rtl">هنا سنقوم بوضع معلومات الاتصال الخاصة  بصفحة {{$store.getters['page/name']}}</p> -->
        </template>

        <template slot="body-slot" v-if="step === 1">
          <div class="vertical-body">
            <p class="rtl">
              هذه الخدمة ستتيح للمتحدثين مع صفحتك أن يحصلو على معلومات التواصل مع شركتك بشكل فوري
              ومباشر، كما سيمكنهم في حال وضعك لرقم هاتف أن يتواصلوا مباشرة
            </p>
            <!-- <div class="guide-img">
                  <img src="./../../../../../static/img/contact-button.png">
                </div>   -->
          </div>
        </template>
      </template>

      <template>
        <template slot="header-slot" v-if="step === 2">
          <p class="rtl">ماهي التغيرات التي ستحدث؟</p>
          <!-- <p class="rtl">هنا سنقوم بوضع معلومات الاتصال الخاصة  بصفحة {{$store.getters['page/name']}}</p> -->
        </template>

        <template slot="body-slot" v-if="step === 2">
          <div class="vertical-body">
            <p class="rtl">
              بعد تفعيل الخدمة سيتم إضافة زر جديد تحت اسم <b>"تواصل معنا"</b> إلى القائمة التي تظهر
              عند مراسلة صفحة <b>{{ $store.getters["page/name"] }}</b> كما الصورة في الأسفل
            </p>
            <div class="guide-img">
              <img src="../../assets/imgs/contact-button.png" />
            </div>
          </div>
        </template>
      </template>

      <template>
        <template slot="header-slot" v-if="step === 3">
          <p class="rtl">ما الذي سيحدث عند الضغط على زر تواصل معنا؟</p>
        </template>

        <template slot="body-slot" v-if="step === 3">
          <div class="vertical-body">
            <p class="rtl">
              عند الضغط على زر "تواصل معنا" ستظهر للمرسل بطاقة تحتوى على عدة أزرار، أحدهم لمعرفة
              العنوان وآخر للاتصال مباشرة برقم التواصل الخاص بالصفحة كما سيتم إضافة زر للتواصل
              مباشرة على Whatsapp إذا رغبت في ذلك
            </p>
            <div class="guide-img">
              <img src="./../../assets/imgs/contact-us.gif" />
            </div>
          </div>
        </template>
      </template>

      <template>
        <template slot="header-slot" v-if="step === 4">
          <p class="rtl">ماهي المعلومات المطلوبة؟</p>
          <!-- <p class="rtl">هنا سنقوم بوضع معلومات الاتصال الخاصة  بصفحة {{$store.getters['page/name']}}</p> -->
        </template>

        <template slot="body-slot" v-if="step === 4">
          <p class="rtl step-header">لتفعيل هذه الخدمة يجب إدخال هذه المعلومات:</p>
          <ul class="list-of-options rtl">
            <li v-for="(item, index) in inputItems" :key="index">{{ item }}</li>
          </ul>
        </template>
      </template>

      <template slot="footer-slot">
        <button class="mujeeb-button" @click="previousStep()" v-if="step !== 1">
          <p>السابق</p>
        </button>

        <button class="mujeeb-button" @click="nextStep()" v-if="step <= numberOfSteps - 1">
          <p>التالي</p>
        </button>

        <button class="mujeeb-button" @click="nextStep()" v-if="step === numberOfSteps">
          <p>البدء بإدخال المعلومات</p>
        </button>
      </template>
    </genericModal>

    <div class="control-section">
      <div class="action-field rtl">
        <div class="m-field-label">
          <label class="label">{{ form.titles.country }}</label>
          <div v-if="typeof form.tooltips.country !== 'undefined'" class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content"
              >{{ form.tooltips.country.text }}
              <a :href="form.tooltips.country.link">{{ form.tooltips.country.linkText }}</a></span
            >
          </div>
        </div>

        <div class="m-field-input">
          <b-field
            :type="shouldAppendInvalidClass($v.form.values.country) ? 'is-danger' : ''"
            data-v-step="1"
          >
            <b-select
              placeholder="الدولة"
              v-model.trim="$v.form.values.country.$model"
              :expanded="true"
              @input="identifyCountryPhoneNumber"
            >
              <option v-for="(option, index) in countries" :value="option" :key="index">
                {{ option }}
              </option>
            </b-select>
          </b-field>
          <p v-if="shouldAppendInvalidClass($v.form.values.country)" class="input-notifier">
            يجب تعبئة هذا الحقل
          </p>
        </div>
      </div>

      <div class="action-field rtl">
        <div class="m-field-label">
          <label class="label">{{ form.titles.city }}</label>
          <div v-if="typeof form.tooltips.city !== 'undefined'" class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content"
              >{{ form.tooltips.city.text }}
              <a :href="form.tooltips.city.link">{{ form.tooltips.city.linkText }}</a></span
            >
          </div>
        </div>

        <div class="m-field-input">
          <input
            v-model.trim="$v.form.values.city.$model"
            data-v-step="2"
            :type="form.types.city"
            :placeholder="form.placeholders.city"
            :class="{ error: $v.form.values.city.$error }"
          />
          <p v-if="shouldAppendInvalidClass($v.form.values.city)" class="input-notifier">
            يجب تعبئة هذا الحقل
          </p>
        </div>
      </div>

      <div class="action-field rtl">
        <div class="m-field-label">
          <label class="label">{{ form.titles.street }}</label>
          <div v-if="typeof form.tooltips.street !== 'undefined'" class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content"
              >{{ form.tooltips.street.text }}
              <a :href="form.tooltips.street.link">{{ form.tooltips.street.linkText }}</a></span
            >
          </div>
        </div>

        <div class="m-field-input" data-v-step="3">
          <input
            v-model.trim="$v.form.values.street.$model"
            :type="form.types.street"
            :placeholder="form.placeholders.street"
            :class="{ error: shouldAppendInvalidClass($v.form.values.street) }"
          />
          <p v-if="$v.form.values.street.$error" class="input-notifier">يجب تعبئة هذا الحقل</p>
        </div>
      </div>

      <div class="action-field rtl">
        <div class="m-field-label">
          <label class="label">{{ form.titles.detailedAddress }}</label>
          <div v-if="typeof form.tooltips.detailedAddress !== 'undefined'" class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content"
              >{{ form.tooltips.detailedAddress.text }}
              <a :href="form.tooltips.detailedAddress.link">{{
                form.tooltips.detailedAddress.linkText
              }}</a></span
            >
          </div>
        </div>

        <div class="m-field-input" data-v-step="4">
          <b-field>
            <b-input
              maxlength="500"
              type="textarea"
              v-model.trim="$v.form.values.detailedAddress.$model"
              :placeholder="form.placeholders.detailedAddress"
            ></b-input>
          </b-field>
          <p v-if="shouldAppendInvalidClass($v.form.values.city)" class="input-notifier">
            يجب تعبئة هذا الحقل
          </p>
        </div>
      </div>

      <div class="action-field rtl" ref="map-button_element">
        <div class="m-field-label">
          <label class="label">{{ form.titles.map }}</label>
          <div v-if="typeof form.tooltips.map !== 'undefined'" class="m-tooltip">
            <img
              class="big-tabs__help-icon"
              src="../../assets/svg/icon.svg"
              alt="Info icon"
              width="15"
              height="15"
            />
            <span class="tooltip-content"
              >{{ form.tooltips.map.text }}
              <a :href="form.tooltips.map.link">{{ form.tooltips.map.linkText }}</a></span
            >
          </div>
        </div>

        <div class="m-field-input">
          <button
            data-v-step="5"
            @click="onMapButtonClicked"
            class="mujeeb-button green square-border"
            style="font-size: 18px;"
          >
            {{ latlng !== "" && latlng ? "عدّل موقعك" : "اختر موقعك على الخريطة" }}
            <font-awesome-icon icon="map-marked-alt" />
          </button>
        </div>
      </div>

      <hr />

      <phoneInput
        v-for="(phone, key) in form.values.phones"
        v-bind:update:number="val => ($v.form.values.phones[key].number = val)"
        v-bind:update:whatsApp="val => ($v.form.values.phones[key].has_whatsApp = val)"
        :key="key"
        :index="key"
        :phone="phone"
        :titles="{
          phone: 'رقم الهاتف',
          whatsApp: 'هل يوجد حساب whatsapp على هذا الرقم؟'
        }"
        :tooltips="{
          phone: {
            text: 'هنا يمكنك إضافة رقم الهاتف الخاص بك',
            link: '',
            linkText: ''
          },
          whatsApp: {
            text: 'هنا يمكنك إضافة رقم الهاتف الخاص بك',
            link: '',
            linkText: ''
          }
        }"
        @hideToolTips="onCountryCodeOpen"
        @phoneChanged="phoneChangeHandler"
        @countryCodeChanged="countryCodeChangehandler"
        @delete:phone="onDeletePhoneEvent(key)"
        @update:countryCode="updateCountryCode($event, key)"
        @update:phonelabel="updatePhonelabel($event, key)"
      >
      </phoneInput>

      <button @click="onAddNewPhone" data-v-step="8" id="ADD_NEW_PHONE_BUTTON">
        <font-awesome-icon icon="plus" /> إضافة رقم أخر
      </button>

      <hr />
    </div>

    <div class="buttons-section">
      <button
        class="mujeeb-button page-info-button red"
        @click="onBackClicked"
        :class="{ hidden: contact_Service_Is_NOT_Initiated }"
      >
        رجوع
      </button>
      <button class="page-info-button mujeeb-button green" @click="submit">
        {{ inEditMode ? "تعديل المعلومات" : "إدخال المعلومات" }}
      </button>
    </div>

    <v-tour
      name="myTour"
      :steps="steps"
      :options="{
        useKeyboardNavigation: true,
        labels: {
          buttonSkip: 'إنهاء',
          buttonPrevious: 'السابق',
          buttonNext: 'التالي',
          buttonStop: 'إنهاء'
        }
      }"
    ></v-tour>

    <loading-spinner position="absolute" v-if="loading"></loading-spinner>
    <gMap
      v-if="showMap"
      :top="scrollTop"
      @close="onCloseModal"
      @update:latlng="latlng = $event"
      :latlng="latlng"
    ></gMap>
  </div>
</template>

<script>
import genericModal from "../utils/genericModal";
import phoneInput from "../input/phoneInput";
import { requiredIf } from "vuelidate/lib/validators";
import allCountries from "../../assets/json/allCountriesNames.json";
import countriesPhones from "../../assets/json/countriesNamesAndPhones.json";
import { EventBus } from "../../utils/EventBus";
import gMap from "./maps";
// eslint-disable-next-line no-useless-escape
const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const optionalValue = value => value !== "" || value === "";
const requiredPhones = phones =>
  phones.every(phoneData => phoneData.number && phoneData.number !== "");
const phoneNumber = arr => arr.every(phoneData => PHONE_REGEX.test(phoneData.number));

export default {
  name: "contact-us-form",
  components: {
    genericModal,
    phoneInput,
    gMap
  },
  props: {
    inEditMode: {
      type: Boolean,
      default: false
    },
    branchInfo: {
      type: Object,
      default: function() {
        return {
          location: {
            city: "",
            country: "",
            street: ""
          },
          phones: {
            type: Array,
            default: function() {
              return [
                {
                  number: "",
                  whatsApp: "false"
                }
              ];
            }
          }
        };
      }
    },
    contact_Service_Is_NOT_Initiated: {
      required: true
    }
  },
  data() {
    return {
      scrollTop: 0,
      countries: allCountries,
      loading: false,
      step: 1,
      showInitializationModal: true, // TODO initialize the value of it when created
      numberOfSteps: 4,
      inputItems: [
        "الدولة المتواجد بها",
        "المدينة المتواجد بها",
        "العنوان/الشارع المتواجد فيه",
        "رقم الهاتف"
      ],
      form: {
        types: {
          city: "text",
          country: "text",
          street: "text",
          phone: "text",
          wahtsApp: "checkbox",
          detailedAddress: "textarea"
        },
        placeholders: {
          city: "دمشق",
          country: "سوريا",
          street: "شعلان",
          phone: "999999999",
          detailedAddress: "دمشق الشعلان بجانب مطعم المأكولات الشهية",
          wahtsApp: true
        },
        values: {
          city: null,
          country: null,
          street: null,
          detailedAddress: null,
          phones: [
            {
              number: "",
              has_whatsApp: false,
              countryCode: "963"
            }
          ]
        },
        tooltips: {
          city: {
            text: "المدينة المتواجد بها",
            link: "",
            linkText: ""
          },
          country: {
            text: "الدولة المتواجد بها",
            link: "",
            linkText: ""
          },
          street: {
            text: "عنوان المنطقة المتواجد بها مثال: الشعلان أو البرامكة",
            link: "",
            linkText: ""
          },
          phone: {
            text: "رقم هاتف المحل (ثابت او جوّال)",
            link: "",
            linkText: ""
          },
          detailedAddress: {
            text:
              "عنوان مفصل لمكان التواجد مثال: رقم البناء او الطابق إو اسم جامع  أو كنيسة كنقطة علّام",
            link: "",
            linkText: ""
          },
          map: {
            text: "يمكنك اختيار المكان الذي تريده عن طريق استخدام الخريطة",
            link: "",
            linkText: ""
          }
        },
        titles: {
          city: "المدينة المتواجد بها:",
          country: "الدولة المتواجد بها:",
          street: "عنوان المنطقة المتواجد بها:",
          phone: "رقم الهاتف:",
          detailedAddress: "العنوان المفصل",
          map: "اختر مكانك باستخدام الخريطة",
          wahtsApp: "هل يوجد حساب whatsapp على هذا الرقم؟"
        }
      },
      countryCode: "963",
      phoneCountrySelected: "",
      steps: [
        {
          target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
          content: `هنا يمكنك اختيار الدولة المتواجد بها`
        },
        {
          target: '[data-v-step="2"]',
          content: "هنا يمكنك اختيار المدينة المتواجد بها"
        },
        {
          target: '[data-v-step="3"]',
          content: " عنوان المنطقة المتواجد بها مثال: البرامكة، الشعلان ..."
        },
        {
          target: '[data-v-step="4"]',
          content: "عنوان التواجد المفصل، هنا يمكنك ذكر اسم نقطة علّام كاسم كنيسة أو جامع معين"
        },
        {
          target: '[data-v-step="5"]',
          content: "يمكنك هنا تحديد مكان التواجد باستخدام الخريطة."
        },
        {
          target: '[data-v-step="6"]',
          content: "هنا يجب إدخال رقم الهاتف الخاص فيك"
        },
        {
          target: '[data-v-step="7"]',
          content: "هنا يتم تحديد فيما اذا كان رقم الهاتف المدخل له حساب whatsapp"
        },
        {
          target: '[data-v-step="8"]',
          content: "هنا يمكنك إضافة رقم هاتف إضافي"
        }
      ],
      hideToolTip: false,
      showMap: false,
      latlng: null
    };
  },
  methods: {
    previousStep() {
      if (this.step >= 2) {
        this.step -= 1;
      }
    },
    nextStep() {
      if (this.step < this.numberOfSteps) {
        this.step += 1;
      } else {
        this.showInitializationModal = false;
        EventBus.$emit("MODAL_CLOSED");
        this.fetchPageContactInfo();
        this.$tours["myTour"].start();
      }
    },
    shouldAppendInvalidClass(field) {
      return field.$error;
    },
    submit() {
      this.$v.form.values.$touch();
      if (!this.$v.form.values.$invalid) {
        this.loading = true;
        const values = Object.keys(this.form.values);
        const pageInfo = values.reduce(
          (pageInfo, value) => {
            if (
              value !== "phones" &&
              value !== "wahtsApp" &&
              typeof this.form.values[value] !== "undefined" &&
              this.form.values[value] &&
              this.form.values[value] !== ""
            ) {
              pageInfo.location[value] = this.form.values[value];
            }
            return pageInfo;
          },
          { location: {} }
        );
        if (
          typeof this.form.values.phones !== "undefined" &&
          this.form.values.phones &&
          this.form.values.phones !== []
        ) {
          pageInfo.phones = this.form.values.phones.map(phone => {
            const tempObj = {
              number: "+" + phone.countryCode + "-" + phone.number,
              has_whatsApp: phone.has_whatsApp,
              countryCode: phone.countryCode
            };
            if (phone.label) {
              tempObj.label = phone.label;
            }
            return tempObj;
          });
        }
        if (this.latlng) {
          pageInfo.location.map = this.latlng;
        }
        if (Object.keys(pageInfo.location).length === 0) {
          delete pageInfo.location;
        }
        const options = {
          url: `/services/contactus/users/${this.$store.getters["user/id"]}/pages/${
            this.$store.getters["page/id"]
          }${
            this.inEditMode
              ? "/" + this.branchInfo.id
              : !this.inEditMode && this.contact_Service_Is_NOT_Initiated
              ? "/initialize"
              : !this.inEditMode && !this.contact_Service_Is_NOT_Initiated
              ? "/contacts"
              : ""
          }`,
          method: this.inEditMode ? "put" : "post",
          data: {
            pageInfo
          },
          responseType: "json"
        };
        return this.$api
          .customRequest(options)
          .then(({ data }) => {
            if (data.success) {
              this.$buefy.toast.open({
                duration: 3000,
                message: `${
                  this.inEditMode ? "تم تعديل المعلومات بنجاح" : "تم إضافة المعلومات بنجاح"
                }`,
                position: "is-top",
                type: "is-success"
              });
              if (!this.$store.getters["page/serviceIsInitialized"]("contact")) {
                this.$store.commit("page/initializeService", "contact");
              }
              this.onCloseModal();
            } else {
              throw new Error("حدث خطأ ما");
            }
          })
          .catch(error => {
            console.log(error);
            this.$buefy.toast.open({
              duration: 3000,
              message: "حدث خطأ ما",
              position: "is-top",
              type: "is-danger"
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit("closeInfoForm");
          });
      } else if (!this.$v.form.values.phones.required) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب إدخال رقم الهاتف",
          position: "is-top",
          type: "is-danger"
        });
      } else if (!this.$v.form.values.phones.integer) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب إدخال أرقام فقط في حقل رقم الهاتف",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب إدخال كافة الحقول المطلوبة",
          position: "is-top",
          type: "is-danger"
        });
      }
    },
    fetchPageContactInfo() {
      this.loading = true;
      const options = {
        // url: `/users/${this.$store.getters['user/id']}/pages/${this.$store.getters['page/id']}/info/contact`,
        url: `/services/contactus/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/contacts`,
        method: "get",
        responseType: "json"
      };
      return this.$api
        .customRequest(options)
        .then(({ data }) => {
          this.populateData(data);
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ ما.",
            position: "is-top",
            type: "is-danger"
          });
          throw error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    populateData(data) {
      const { location, phones } = data // eslint-disable-line
      if (location && location.city) {
        this.$v.form.values.city.$model = location.city;
      }
      if (location && location.country) {
        this.$v.form.values.country.$model = location.country;
        this.countryCode = countriesPhones[this.$v.form.values.country.$model];
      }
      if (location && location.street) {
        this.$v.form.values.street.$model = location.street;
      }
      if (typeof phones !== "undefined") {
        if (typeof phones && Object.keys(phones).length > 0) {
          this.$v.form.values.phones.$model = phones.map(({ number, countryCode, has_whatsApp, label }) => { // eslint-disable-line
            const temp = { number: number.split('-')[1], countryCode, has_whatsApp } // eslint-disable-line
              if (label) {
                temp.label = label;
              }
              return temp;
          }) // eslint-disable-line
        } else {
          this.$v.form.values.phones.$model = "";
        }
      }
      if (location && location.detailedAddress) {
        this.$v.form.values.detailedAddress.$model = location.detailedAddress;
      }
      if (location && location.map) {
        this.latlng = location.map;
      }
    },
    identifyCountryPhoneNumber(selectedCountry) {
      this.countryCode = countriesPhones[selectedCountry];
      // change all phones country codes
      this.form.values.phones.map(phone => this.$set(phone, "countryCode", this.countryCode));
    },
    changeInputMask(countryCode) {
      if (countryCode.length > 0) {
        this.countryCode = "";
        for (let i = 0; i < countryCode.length; i++) {
          this.countryCode += "#";
        }
      }
    },
    phoneChangeHandler(phone) {
      this.$v.form.values.phones.$model = phone;
    },
    countryCodeChangehandler(countryCode) {
      this.countryCode = countryCode;
    },
    onCountryCodeOpen(value) {
      this.hideToolTip = value;
    },
    onAddNewPhone() {
      this.$set(this.form.values.phones, this.form.values.phones.length, {
        number: "",
        has_whatsApp: false,
        countryCode: this.countryCode
      });
    },
    onDeletePhoneEvent(key) {
      this.$delete(this.form.values.phones, key);
    },
    updateCountryCode(countryCode, key) {
      this.$v.form.values.phones.$model[key].countryCode = countryCode;
    },
    updatePhonelabel(label, key) {
      if (label !== "") {
        this.$set(this.form.values.phones[key], "label", label);
      } else {
        this.$delete(this.form.values.phones[key], "label");
      }
    },
    onMapButtonClicked() {
      this.showMap = true;
      EventBus.$emit("MODAL_OPENED");
    },
    onScrolling() {
      this.scrollTop = this.$refs["contactUs_Component"].scrollTop;
    },
    onCloseModal() {
      this.showMap = false;
      EventBus.$emit("MODAL_CLOSED");
    },
    onBackClicked() {
      if (!this.contact_Service_Is_NOT_Initiated) {
        this.$emit("closeInfoForm");
      }
    }
  },
  validations: {
    form: {
      values: {
        city: {
          required: optionalValue,
          requiredIf: requiredIf(function() {
            return this.form.values.street;
          })
        },
        country: {
          required: optionalValue,
          requiredIf: requiredIf(function() {
            return this.form.values.street || this.form.values.city;
          })
        },
        street: {
          required: optionalValue
        },
        detailedAddress: {
          required: optionalValue
        },
        phones: {
          required: requiredPhones,
          integer: phoneNumber
        }
      }
    }
  },
  created() {
    // window.addEventListener('scroll', this.onScrolling)
    this.showInitializationModal = !this.$store.getters["page/serviceIsInitialized"]("contact");
    if (this.inEditMode) {
      this.populateData(this.branchInfo);
      EventBus.$emit("MODAL_CLOSED");
    } else if (this.showInitializationModal) {
      EventBus.$emit("MODAL_OPENED");
      this.fetchPageContactInfo();
    } else {
      EventBus.$emit("MODAL_CLOSED");
      // console.log('inEditMode: ' + this.inEditMode)
      // console.log('showInitializationModal: ' + this.showInitializationModal)
    }
  },
  beforeDestroy() {
    EventBus.$emit("MODAL_CLOSED");
  }
};
</script>

<style lang="scss" scoped>
// @import "./../../../../../static/sass/_variables.scss";
.m-main-section {
  // position: relative;
  .m-field-label {
    .m-tooltip {
      z-index: 2;
    }
  }
  .section-title {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    margin: 30px;
    margin-bottom: 50px;
  }
  .vertical-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .control-section {
    display: block;
    .action-field {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .m-tooltip {
        margin-left: 15px;
        &.lowest-z-index {
          z-index: 0;
        }
      }
      .m-field-input {
        button {
          &.square-border {
            border-radius: $border-radius-xs;
            i {
              margin-right: 10px;
              font-size: 18px;
            }
          }
        }
        input {
          border: 1px solid transparent;
          background-color: white;
          border-color: #dbdbdb;
          color: #363636;
          box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
          max-width: 100%;
          width: 100%;
          border-radius: 4px;
          padding: 8px;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: rgb(218, 218, 218);
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: rgb(218, 218, 218);
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgb(218, 218, 218);
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: rgb(218, 218, 218);
          }
          &:focus {
            border-color: dodgerblue;
            -webkit-box-shadow: 0 0 0 0.125em rgba(30, 144, 255, 0.25);
            box-shadow: 0 0 0 0.125em rgba(30, 144, 255, 0.25);
          }
          &.error {
            border-color: $danger;
            // box-shadow: inset 0 1px 2px $danger;
            &:focus {
              box-shadow: 0 0px 2px $danger;
            }
          }
        }
        .input-notifier {
          color: $danger;
        }
      }
      &.phone {
        .m-field-input {
          direction: ltr;
          p.input-notifier {
            direction: rtl;
          }
          input {
            direction: ltr;
          }
        }
      }
    }
    #ADD_NEW_PHONE_BUTTON {
      width: 100%;
      padding: 20px;
      text-align: cetner;
      background: white;
      color: gray;
      border: 2px dashed gray;
      border-radius: $border-radius-xs;
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
  }
  .buttons-section {
    margin-bottom: 40px;
    margin-top: 40px;
    display: flex;
    justify-content: space-evenly;
    flex-flow: wrap;
    .page-info-button {
      border-radius: 10px;
      text-align: center;
      display: flex;
      height: 40px;
      line-height: 18px;
      font-size: 18px;
      align-content: center;
      align-items: center;
      &.hidden {
        display: none;
      }
    }
  }
  .list-of-options {
    display: inline-block;
    margin: auto;
    list-style-type: decimal;
    text-align: right;
  }
  .step-header {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .phone-section {
    &:first-child {
      background: white !important;
    }
  }
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: 30px;
  span {
    margin-right: 10px;
  }
}
</style>
