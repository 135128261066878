<template>
  <div class="m-wrapper">
    <contactUs
      key="1"
      v-if="showContactForm"
      :contact_Service_Is_NOT_Initiated="contact_Service_Is_NOT_Initiated"
      :inEditMode="editMode"
      :branchInfo="contactInfoToEdit"
      @closeInfoForm="onCloseContactInfoForm"
    ></contactUs>
    <contactsTable
      key="2"
      v-else
      @edit:contactInfo="onEditContactInfo"
      @add:contactInfo="onAddContactInfo"
      @openContactForm="onOpenContactForm"
    ></contactsTable>
  </div>
</template>

<script>
import contactUs from "../components/contactUs/contactUs";
import contactsTable from "../components/contactUs/contactUsTable.vue";
export default {
  name: "ContactUsDashboard",
  components: {
    contactUs,
    contactsTable
  },
  data() {
    return {
      contact_Service_Is_NOT_Initiated: true,
      contactInfoToEdit: null,
      editMode: false,
      showContactForm: false
    };
  },
  methods: {
    onEditContactInfo(payload) {
      this.editMode = true;
      this.contactInfoToEdit = payload;
      this.showContactForm = true;
    },
    onAddContactInfo() {
      this.editMode = false;
      this.contactInfoToEdit = null;
      this.showContactForm = true;
    },
    onCloseContactInfoForm() {
      this.showContactForm = false;
      this.editMode = false;
      this.contactInfoToEdit = null;
    },
    onOpenContactForm() {
      this.editMode = false;
      this.contactInfoToEdit = null;
      this.showContactForm = true;
      this.contact_Service_Is_NOT_Initiated = true;
    }
  },
  created() {
    this.contact_Service_Is_NOT_Initiated = this.showContactForm = !this.$store.getters[
      "page/serviceIsInitialized"
    ]("contact");
  }
};
</script>

<style scoped lang="scss"></style>
