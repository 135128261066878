<template>
  <div class="mujeeb-contact-us">
    <div v-if="!loading">
      <div class="mujeeb-contact-us_section-title">
        <p>
          هنا يمكنك إضافة عنوان او عناوين الفروع التي تمتلكها كما يمكنك حذف أو تعديل أي عنوان تم
          إدخاله سابقا
        </p>
      </div>
      <div class="mujeeb-contact-us_control">
        <button @click="onStopContactService" class="us_control mujeeb-button red icon-on-left">
          <font-awesome-icon :icon="contactUsServiceEnabled ? 'ban' : 'check'" size="sm" />
          {{ contactUsServiceEnabled ? "إيقاف الخدمة" : "تفعيل الخدمة" }}
        </button>
        <button @click="onAddNewBranchClicked" class="us_control mujeeb-button green icon-on-left">
          <font-awesome-icon icon="plus" size="sm" />إضافة فرع جديد
        </button>
      </div>
      <div class="m-table">
        <div class="row i-6 m-table-header">
          <div class="item i-6"><font-awesome-icon icon="globe-africa" /> الدولة</div>
          <div class="item i-6"><font-awesome-icon icon="city" /> المدينة</div>
          <div class="item i-6"><font-awesome-icon icon="road" /> المنطقة</div>
          <div class="item i-6"><font-awesome-icon icon="phone" /> هاتف</div>
          <div class="item i-6"><font-awesome-icon icon="map" /> العنوان التفصيلي</div>
          <div class="item i-6">تعديل</div>
        </div>
        <div class="row i-6" v-for="(contact, key) in contactInfo" :key="key">
          <div
            class="item"
            :class="{ 'gray-text': contact.location.country === '' }"
            data="الدولة:"
          >
            {{ normalizeText(contact.location.country, 13) }}
          </div>
          <div class="item" :class="{ 'gray-text': contact.location.city === '' }" data="المدينة:">
            {{ contact.location.city }}
          </div>
          <div
            class="item"
            :class="{ 'gray-text': contact.location.street === '' }"
            data="المنطقة:"
          >
            {{ normalizeText(contact.location.street, 20) }}
          </div>
          <div
            class="item phone"
            :class="{ 'gray-text': contact.location.phones === [] }"
            data=":الهاتف"
          >
            <p
              v-for="(phone, index) in contact.phones"
              :key="index"
              :class="{ green: phone.has_whatsApp }"
              :style="
                `padding: 2px 10px; color: white; display: inline-block; margin: 3px; border-radius: 6px;`
              "
            >
              {{ phone.number }}
            </p>
          </div>
          <div
            class="item"
            :class="{ 'gray-text': contact.location.detailedAddress === '' }"
            data="العنوان المفصل:"
          >
            {{ normalizeText(contact.location.detailedAddress, 30) }}
          </div>
          <div class="item control">
            <button class="m-edit" data="تعديل" @click="onEditClicked(contact)">
              <font-awesome-icon icon="edit" />
            </button>
            <button
              v-if="contactInfo.length > 1"
              class="m-delete"
              @click="onDeleteClicked(contact)"
              data="حذف"
            >
              <font-awesome-icon icon="trash-alt" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading-spinner v-else position="absolute"></loading-spinner>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactInfo: [],
      loading: false
    };
  },
  methods: {
    parseInfo(data) {
      // console.log(JSON.parse(JSON.stringify(data)))
      const infoArray = Object.keys(data).map(contactId => {
        const contactObj = data[contactId];
        if (contactObj.location) {
          contactObj.location.country = contactObj.location.country || "";
          contactObj.location.city = contactObj.location.city || "";
          contactObj.location.street = contactObj.location.street || "";
          contactObj.location.detailedAddress = contactObj.location.detailedAddress || "";
        } else {
          contactObj.location = {};
          contactObj.location.country = "";
          contactObj.location.city = "";
          contactObj.location.street = "";
          contactObj.location.detailedAddress = "";
        }
        contactObj.phones = contactObj.phones || [];
        contactObj.id = contactId;
        return contactObj;
      });
      // console.log(infoArray)
      return infoArray;
    },
    onEditClicked(info) {
      this.$emit("edit:contactInfo", info);
    },
    onAddNewBranchClicked() {
      this.$emit("add:contactInfo");
    },
    onDeleteClicked(info) {
      if (this.contactInfo.length > 1) {
        this.$buefy.dialog.confirm({
          title: "حذف معلومات الفرع",
          message: "هل أنت متاكد من رغبتك <b>بحذف</b> معلومات هذا الفرع؟",
          confirmText: "متأكد",
          cancelText: "كلا",
          type: "is-danger",
          onConfirm: () =>
            this.makeRequest("delete", info.id)
              .then(success => {
                return this.makeRequest();
              })
              .then(() => {
                this.$buefy.toast.open({
                  duration: 3000,
                  message: "تم إزالة معلومات الفرع",
                  position: "is-top",
                  type: "is-success"
                });
              })
              .finally(() => {
                this.loading = false;
              })
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: "لا يمكن حذف هذا ا",
          position: "is-top",
          type: "is-success"
        });
      }
    },
    onStopContactService() {
      // console.log('stoping the service')
      this.$buefy.dialog.confirm({
        title: "إيقاف الخدمة",
        message: `هل أنت متاكد من رغبتك <b>${
          this.contactUsServiceEnabled ? "بإيقاف" : "بتفعيل"
        }</b> الخدمة؟`,
        confirmText: "متأكد",
        cancelText: "كلا",
        type: "is-danger",
        onConfirm: () =>
          this.changeContactUsServiceState(!this.contactUsServiceEnabled)
            .then(() => {
              this.loding = true;
              this.$buefy.toast.open({
                duration: 3000,
                message: "تم تغيير حالة الخدمة",
                position: "is-top",
                type: "is-success"
              });
            })
            .catch(error => {
              console.log(error);
              this.$buefy.toast.open({
                duration: 3000,
                message: "حدث خطأ ما أثناء تغيير حالة الخدمة",
                position: "is-top",
                type: "is-danger"
              });
            })
            .finally(() => {
              this.loading = false;
            })
      });
    },
    changeContactUsServiceState(newState) {
      this.loading = true;
      // console.log('changeContactUsServiceState')
      // console.log(newState)
      const options = {
        url: `/services/contactus/users/${this.$store.getters["user/id"]}/pages/${
          this.$store.getters["page/id"]
        }/${newState ? "enable" : "disable"}`,
        method: "post",
        responseType: "json"
      };
      return this.$api.customRequest(options).then(({ data }) => {
        // console.log(data)
        return this.$store.dispatch("page/changeServiceState", {
          serviceName: "contact",
          serviceState: !this.contactUsServiceEnabled
        });
      });
    },
    makeRequest(method = "get", id) {
      this.loading = true;
      const options = {
        url: `/services/contactus/users/${this.$store.getters["user/id"]}/pages/${
          this.$store.getters["page/id"]
        }${method === "delete" ? "/" + id : "/contacts"}`,
        method,
        responseType: "json"
      };
      return this.$api
        .customRequest(options)
        .then(({ data }) => {
          if (method === "get") {
            // console.log(data)
            this.contactInfo = this.parseInfo(data);
            if (this.contactInfo.length === 0) {
              // console.log('hi')
              this.$emit("openContactForm");
            }
          }
          return true;
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ ما. داخل ال table",
            position: "is-top",
            type: "is-danger"
          });
          throw error;
        });
    },
    normalizeText(value, length) {
      if (this.$store.getters["windowWidth"] > 1450) {
        return value && value.length > length ? value.slice(0, length) + "..." : value;
      } else if (value === "") {
        return "لم يتم إدخال قيمة";
      } else {
        return value;
      }
    }
  },
  computed: {
    contactUsServiceEnabled() {
      return (
        (this.$store.getters["page/serviceIsInitialized"]("contact") &&
          typeof this.$store.getters["page/services"].contact.enabled === "undefined") ||
        this.$store.getters["page/serviceIsEnabled"]("contact")
      );
    }
  },
  mounted() {
    this.makeRequest().finally(() => {
      this.loading = false;
    });
  }
};
</script>

<style scoped lang="scss">
// @import "./../../../../../static/sass/_variables.scss";
.mujeeb-contact-us {
  direction: rtl;
  position: relative;
  .mujeeb-contact-us_control {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    // .us_control {
    //   padding: 7px 20px;
    //   border-radius: 10px;
    //   border: none;
    //   display: flex;
    //   justify-content: space-between;
    //   align-content: center;
    //   align-items: center;
    //   cursor: pointer;
    //   margin-left: 10px;
    //   margin-bottom: 10px;
    //   font-weight: 900;
    //   &.add-button {
    //     // background: #d7ffec;
    //     background: white;
    //     color: #00cd66;
    //     border: 1px solid rgb(180, 180, 180);
    //     // background: rgba(0, 255, 46, 0.13);
    //     // border: 1px solid #00cd66;
    //     // box-shadow: 0 4px 9px 0 rgba(0, 255, 46, 0.21), 0 3px 10px 0 rgba(17, 255, 0, 0.08);
    //   }
    //   &.delete-button {
    //     // background: #ffe5e7;
    //     background: white;
    //     color: #f75454;
    //     border: 1px solid rgb(180, 180, 180);
    //     // background: #ffe5e7;
    //     // border: 1px solid #f75454;
    //     // box-shadow: 0 4px 9px 0 rgba(255, 0, 0, 0.15), 0 3px 10px 0 rgba(239, 30, 30, 0.08);
    //   }
    //   svg {
    //     color: inherit;
    //     padding: 0px;
    //     margin: 0px;
    //     margin-left: 15px;
    //   }
    // }
  }
  .mujeeb-contact-us_section-title {
    margin-bottom: 20px;
    p {
      font-size: 18px;
      text-align: center;
      padding: 20px;
      font-weight: 700;
    }
  }
  .m-table {
    width: 100%;
    display: block;
    margin-top: 30px;
    .row {
      display: flex;
      align-content: center;
      align-items: center;
      padding: 4px;
      border-radius: 6px;
      padding: 10px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      font-weight: 600;
      font-size: 16px;
      margin: 20px;
      &.m-table-header {
        margin-bottom: 20px;
        font-weight: bold;
        box-shadow: none;
        .item {
          svg {
            margin-left: 10px;
            font-size: 12px;
          }
          &.gray-text {
            color: rgba(194, 194, 194, 0.555);
          }
        }
      }
      &.i-6 {
        .item {
          flex: 1 0 16.66%;
          &.phone {
            direction: ltr;
            text-align: right;
            p {
              background: gray;
              display: flex;
              justify-items: center;
              align-content: center;
              align-items: center;
              &.green {
                background: $success;
              }
            }
          }
          &:last-child {
            text-align: left;
            display: flex;
            justify-content: flex-end;
          }
          &.control {
            button {
              border: none;
              width: 30px;
              height: 30px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-content: center;
              align-items: center;
              margin-left: 10px;
              border-radius: 6px;
              padding: 18px;
              cursor: pointer;
              &:last-child {
                margin: 0px;
              }
              svg {
                margin: 0;
                padding: 0;
                font-size: 15px;
              }
              &.m-delete {
                background: #ffe5e7;
                svg {
                  color: #f75454;
                }
              }
              &.m-edit {
                background: #d9f9f5;
                svg {
                  color: dodgerblue;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .m-table {
    .row {
      margin: 20px 0px !important;
      padding: 15px !important;
      display: block !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.219), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
      .item {
        display: block;
        margin-bottom: 20px;
        &::before {
          font-family: cairo;
          font-weight: 900;
          font-size: 16px;
          color: #000000;
          line-height: 1.2;
          margin-bottom: 6px;
          content: attr(data);
          min-width: 98px;
          display: block;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: rgba(177, 177, 177, 0.445);
          margin: 10px 0px;
        }
        &.control {
          display: flex !important;
          justify-content: center !important;
          align-content: center !important;
          align-items: center !important;
          &::after {
            display: none;
          }
          &::before {
            display: none;
          }
          margin: 0px;
          button {
            display: flex !important;
            width: auto !important;
            height: auto !important;
            padding: 5px;
            &::after {
              content: attr(data);
              display: block;
              margin-right: 10px;
            }
            &.m-edit {
              &::after {
                color: dodgerblue;
              }
            }
            &.m-delete {
              &::after {
                color: #f75454;
              }
            }
          }
        }
        &.gray-text {
          color: rgba(194, 194, 194, 0.555);
        }
      }
    }
    .m-table-header {
      display: none !important;
      color: red;
    }
  }
}
</style>
