<template>
  <div class="phone-section">
    <div class="action-field rtl">
      <div class="m-field-label">
        <label class="label">{{ titles.phone }}:</label>
        <div v-if="typeof tooltips !== 'undefined'" class="m-tooltip">
          <img
            class="big-tabs__help-icon"
            src="../../assets/svg/icon.svg"
            alt="Info icon"
            width="15"
            height="15"
          />
          <span class="tooltip-content"
            >{{ tooltips.phone.text }}
            <a :href="tooltips.phone.link">{{ tooltips.phone.linkText }}</a></span
          >
        </div>
      </div>
      <div class="m-field-input">
        <div class="horizontal-div">
          <input
            data-v-step="6"
            type="phone"
            name=""
            class="has-left-add-on"
            :value="phone.number"
            @input="phoneChanged($event.target.value)"
          />
          <div class="m-dropdown">
            <span
              class="m-button unselectable"
              @click="onCountryCodeClicked"
              v-click-outside="onClickOutsideCountries"
              >{{
                phone.countryCode === "رمز الدولة" ? phone.countryCode : "+ " + phone.countryCode
              }}</span
            >
            <div class="m-dropdown-items unselectable" :class="{ hidden: hideDropDownMenu }">
              <div
                class="m-drop-down-item unselectable"
                v-for="item in countriesData"
                :key="item.code"
                @click="selectDropDownItem(item)"
              >
                <p class="unselectable">{{ item.name }}</p>
                <CountryFlag :country="item.code" size="small" />
              </div>
            </div>
          </div>
        </div>
        <p v-if="!$v.phone.required" class="input-notifier">يجب تعبئة هذا الحقل</p>
        <p v-else-if="!$v.phone.integer" class="input-notifier">يجب إدخال أرقام فقط</p>
      </div>
    </div>

    <!--  -->

    <div class="action-field rtl">
      <div class="m-field-label">
        <label class="label">اسم مرافق لهذا الهاتف</label>
        <div class="m-tooltip">
          <img
            class="big-tabs__help-icon"
            src="../../assets/svg/icon.svg"
            alt="Info icon"
            width="15"
            height="15"
          />
          <span class="tooltip-content"
            >هنا يمكنك وضع اسم لحامل الرقم أو مثلا نوع الرقم "موبايل" او "أرضي" أو فاكس"</span
          >
        </div>
      </div>

      <div class="m-field-input">
        <input v-model.trim="phone.label" type="text" placeholder="أرضي" />
      </div>
    </div>

    <!--  -->

    <div class="action-field rtl">
      <div class="m-field-label">
        <label class="label">{{ titles.whatsApp }}</label>
        <div
          v-if="typeof tooltips !== 'undefined'"
          class="m-tooltip"
          :class="{ 'lowest-z-index': hideToolTips }"
        >
          <!-- TODO change the true to a prop-->
          <img
            class="big-tabs__help-icon"
            src="../../assets/svg/icon.svg"
            alt="Info icon"
            width="15"
            height="15"
          />
          <span class="tooltip-content"
            >{{ tooltips.whatsApp.text }}
            <a :href="tooltips.whatsApp.link">{{ tooltips.whatsApp.linkText }}</a></span
          >
        </div>
      </div>

      <div class="m-field-input">
        <b-checkbox data-v-step="7" v-model.trim="phone.has_whatsApp">
          {{ phone.has_whatsApp ? "نعم" : "كلا" }}
        </b-checkbox>
      </div>
    </div>
    <button class="delettion-button" v-if="index !== 0" @click="onDeleteClicked">
      حذف الرقم <font-awesome-icon icon="trash-alt" />
    </button>
  </div>
</template>

<script>
import countriesData from '../../assets/json/countriesData.json' // eslint-disable-line
import CountryFlag from "vue-country-flag";
const isInteger = phone => {
  return !isNaN(parseInt(phone.number));
};
const isRequired = phone => {
  return phone.number !== "";
};
export default {
  props: {
    titles: {
      type: Object,
      required: true,
      default: function() {
        return {
          phone: "نص مؤقت",
          whatsApp: "نص مؤقت"
        };
      }
    },
    tooltips: {
      type: Object,
      default: function() {
        return {
          text: "",
          link: "",
          linkText: ""
        };
      }
    },
    phone: {
      type: Object,
      default: function() {
        return {
          number: "",
          countryCode: "رمز الدولة",
          has_whatsApp: false
        };
      }
    },
    index: {
      type: Number,
      required: true
    }
  },
  components: {
    CountryFlag
  },
  data() {
    return {
      hideDropDownMenu: true,
      countriesData: countriesData,
      countryCode: "963",
      hideToolTips: false
    };
  },
  methods: {
    selectDropDownItem(country) {
      if (country) {
        this.countryCode = country.phone;
        // this.$emit('countryCodeChanged', this.countryCode)
        this.$emit("update:countryCode", country.phone);
        this.hideDropDownMenu = true;
      } else {
        console.log("not found");
      }
      this.hideToolTips = false;
    },
    phoneChanged(phone) {
      // this.$emit('phoneChanged', phone)
      this.$emit("update:number", phone);
      this.phone.number = phone.trim();
    },
    onClickOutsideCountries() {
      this.hideDropDownMenu = true;
      // this.$emit('hideToolTips', false)
      this.hideToolTips = false;
    },
    onCountryCodeClicked() {
      // this.$emit('hideToolTips', this.hideDropDownMenu)
      this.hideDropDownMenu = !this.hideDropDownMenu;
      this.hideToolTips = true;
    },
    onDeleteClicked() {
      this.$emit("delete:phone");
    }
  },
  watch: {
    "phone.label": {
      handler: function(newVal, oldVal) {
        this.$emit("update:phonelabel", newVal);
      }
    }
  },
  validations: {
    phone: {
      required: isRequired,
      integer: isInteger
    }
  }
};
</script>

<style scoped lang="scss">
// @import "./../../../../static/sass/_variables.scss";
.phone-section {
  background: #f7faff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  &:first-child {
    background: white;
  }
  .delettion-button {
    background: #ffe5e7;
    color: #f75454;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    i {
      font-size: 14px;
    }
  }
  .action-field {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    .m-tooltip {
      margin-left: 15px;
      &.lowest-z-index {
        z-index: 0;
      }
    }
    .horizontal-div {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
    }
    .m-field-input {
      display: block;
      .m-dropdown {
        z-index: 1;
        height: 36px;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        // border: 1px solid black;
        // width: 80px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        cursor: pointer;
        position: relative;
        flex: 0 0 auto;
        color: white;
        background: $mujeeb-blue;
        .m-button {
          direction: ltr;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 16px;
          line-height: 16px;
          padding: 0 10px;
        }
        .m-dropdown-items {
          color: black;
          position: absolute;
          top: 45px;
          left: 0;
          height: 200px;
          overflow-y: scroll;
          background: white;
          border-radius: 6px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
          &::-webkit-scrollbar {
            width: 0px; /* remove scrollbar space */
            background: transparent; /* optional: just make scrollbar invisible */
          }
          &.hidden {
            display: none;
          }
          .m-drop-down-item {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            justify-content: space-between;
            padding: 0px 15px;
            height: 45px;
            max-width: 280px;
            &:hover {
              background: rgb(240, 240, 240);
            }
            p {
              white-space: nowrap;
              word-break: keep-all;
            }
          }
        }
      }
      input {
        border: 1px solid transparent;
        background-color: white;
        border-color: #dbdbdb;
        color: #363636;
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        max-width: 100%;
        width: 100%;
        border-radius: 4px;
        padding: 8px;
        flex: 1 1;
        direction: rtl;
        &.has-left-add-on {
          border-bottom-left-radius: 0px;
          border-top-left-radius: 0px;
          direction: ltr;
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: rgb(218, 218, 218);
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: rgb(218, 218, 218);
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: rgb(218, 218, 218);
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: rgb(218, 218, 218);
        }
        &:focus {
          border-color: dodgerblue;
          -webkit-box-shadow: 0 0 0 0.125em rgba(30, 144, 255, 0.25);
          box-shadow: 0 0 0 0.125em rgba(30, 144, 255, 0.25);
        }
        &.error {
          border-color: $danger;
          // box-shadow: inset 0 1px 2px $danger;
          &:focus {
            box-shadow: 0 0px 2px $danger;
          }
        }
      }
      .input-notifier {
        color: $danger;
      }
    }
    p.input-notifier {
      direction: rtl;
      color: $danger;
    }
    &.phone {
      .m-field-input {
        direction: ltr;
        p.input-notifier {
          direction: rtl;
        }
        input {
          direction: ltr;
        }
      }
    }
  }
}
</style>
